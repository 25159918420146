//import React, { Component } from 'react';
//import Particles from 'react-tsparticles';

//import React from "react";
import React, { useLayoutEffect, useState } from 'react';
import Particles from "react-tsparticles";
import css from './style.css';
import images_pic01 from './assets/images_pic01.png';
import images_pic02 from './assets/images_pic02.png';
import images_pic03 from './assets/images_pic03.png';
import images_pic04 from './assets/images_pic04.png';
import images_pic05 from './assets/images_pic05.png';
import images_pic06 from './assets/images_pic06.png';
import images_pic07 from './assets/images_pic07.png';
import images_pic08 from './assets/images_pic08.png';
import images_pic09 from './assets/images_pic09.png';
import images_pic10 from './assets/images_pic10.png';
import images_pic11 from './assets/images_pic11.png';
import images_pic12 from './assets/images_pic12.png';
import images_pic13 from './assets/images_pic13.png';
import images_pic14 from './assets/images_pic14.png';
import images_pic15 from './assets/images_pic15.png';
import images_pic16 from './assets/images_pic16.png';
import images_pic17 from './assets/images_pic17.png';
import images_pic18 from './assets/images_pic18.png';
import images_pic19 from './assets/images_pic19.png';
import images_pic20 from './assets/images_pic20.png';
import images_pic21 from './assets/images_pic21.png';
import images_pic22 from './assets/images_pic22.png';

import images_text01 from './assets/images_text01.png';
import images_text02 from './assets/images_text02.png';
import images_text03 from './assets/images_text03.png';
import images_text04 from './assets/images_text04.png';
import images_text05 from './assets/images_text05.png';
import images_text06 from './assets/images_text06.png';
import images_text07 from './assets/images_text07.png';
import images_text08 from './assets/images_text08.png';
import images_text09 from './assets/images_text09.png';
import images_text10 from './assets/images_text10.png';
import images_text11 from './assets/images_text11.png';
import images_text12 from './assets/images_text12.png';
import images_text13 from './assets/images_text13.png';
import images_text14 from './assets/images_text14.png';
import images_text15 from './assets/images_text15.png';
import images_text16 from './assets/images_text16.png';
import images_text17 from './assets/images_text17.png';
import images_text18 from './assets/images_text18.png';
import images_text19 from './assets/images_text19.png';
import images_text20 from './assets/images_text20.png';
import images_text21 from './assets/images_text21.png';
import images_text22 from './assets/images_text22.png';
import images_text23 from './assets/images_text23.png';
import images_text24 from './assets/images_text24.png';
import images_text25 from './assets/images_text25.png';
import images_text26 from './assets/images_text26.png';
import images_text27 from './assets/images_text27.png';
import images_text28 from './assets/images_text28.png';
import images_text29 from './assets/images_text29.png';
import images_text30 from './assets/images_text30.png';
import images_text31 from './assets/images_text31.png';
import images_text32 from './assets/images_text32.png';
import images_text33 from './assets/images_text33.png';
import images_text34 from './assets/images_text34.png';
import images_text35 from './assets/images_text35.png';

const App = () => {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

var resize = useWindowSize();

var size; var minSize; var distance; var direction;
if(resize <= 900){
	var valuearea = resize + 500; distance = 10000; direction = "none";
	size = 70; minSize = 20;
}else{
	var valuearea = resize + 1500; distance = 5000; direction = "top-right";
	size = 100; minSize = 40;
}
	
const particlesLoaded = (container) => {
	console.log(container);
  };
  return (
	<Particles
	  options={{
		fullScreen: {
		  enable: true,
		  zIndex: 0
		},
		particles: {
		  number: {
			value: 79,
			limit: 79,
			density: {
			  enable: true,
			  value_area: valuearea
			}
		  },	  
		 color: {
			  value: ["#f7d246", "#41a9a5", "#889dd9", "#f67e62", "#3490d1"]
			},
		  shape: {
			type: ["images"], //circle
			stroke: {
			  width: 0,
			  color: "#000000"
			},
			polygon: {
			  nb_sides: 10
			},
			image: [
				{
					"src": images_pic01,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic02,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic03,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic04,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic05,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic06,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic07,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic08,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic09,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic10,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic11,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic12,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic13,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic14,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic15,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic16,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic17,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic18,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic19,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic20,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic21,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic22,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_pic01,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic02,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic03,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic04,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic05,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic06,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic07,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic08,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic09,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic10,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic11,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic12,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic13,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic14,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic15,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic16,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic17,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic18,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic19,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic20,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic21,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_pic22,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text01,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text02,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text03,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text04,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text05,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text06,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text07,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text08,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text09,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text10,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text11,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text12,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text13,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text14,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text15,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text16,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text17,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text18,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text19,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text20,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text21,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text22,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text23,
					"height": 100,
					"width": 100,
				},
				{
					"src": images_text24,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text25,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text26,
					"height": 100,
					"width": 100,
				},	  
	  			{
					"src": images_text27,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text28,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text29,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text30,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text31,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text32,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text33,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text34,
					"height": 100,
					"width": 100,
				},
	  			{
					"src": images_text35,
					"height": 100,
					"width": 100,
				},
			]
		  },
		  opacity: {
			value: 1,
			random: false,
			anim: {
			  enable: true,
			  speed: 1,
			  opacity_min: 1,
			  sync: false
			}
		  },
		  size: {
			value: size,
			random: false,
			anim: {
			  enable: true,
			  speed: 20,
			  size_min: minSize,
			  sync: false
			}
		  },
		  line_linked: {
			enable: false,
			distance: distance,
			color: "#222222",
			opacity: .5,
			width: 1
		  },
		  move: {
			enable: true,
			speed: 4,
			direction: direction,
			random: true,
			straight: false,
			out_mode: "out",
			bounce: true,
			attract: {
			  enable: false,
			  rotateX: 2600,
			  rotateY: 1600
			}
		  }
		},
		interactivity: {
		  detect_on: "window",
		  events: {
			onHover: {
			  enable: true,
			  mode: "bubble",
			  parallax: {
				enable: true,
				force: 200,
				smooth: 50
			  }
			},
			onClick: {
			  enable: false,
			  mode: "push"
			},
			resize: true
		  },
		  modes: {
			grab: {
			  distance: 600,
			  lineLinked: {
				opacity: 1
			  }
			},
			bubble: {
			  distance: 400,
			  size: 100,
			  duration: 2,
			  opacity: 1,
			  speed: 4
			},
			repulse: {
			  distance: 400
			},
			push: {
			  particles_nb: 4
			},
			remove: {
			  particles_nb: 2
			}
		  }
		},
		detectRetina: true,
		fpsLimit: 60,
		background: {
		  color: "#fff"
		}
	  }}
	/>
  );
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  });
  return size;
}
//const App = () => {
//  const particlesInit = (main) => {
//    console.log(main);
//
//    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
//  };
//
//  const particlesLoaded = (container) => {
//    console.log(container);
//  };
//
//  return (
//    <Particles id="tsparticles" url="http://foo.bar/particles.json" init={particlesInit} loaded={particlesLoaded} />
//  );
//};
//
////import React from 'react';
////import './App.css';
////
////function App() {
////  return <h1>Hello, world!</h1>;
////}
////
//////import logo from './logo.svg';
//////import './App.css';
//////
//////function App() {
//////  return (
//////    <div className="App">
//////      <header className="App-header">
//////        <img src={logo} className="App-logo" alt="logo" />
//////        <p>
//////          Edit <code>src/App.js</code> and save to reload.
//////        </p>
//////        <a
//////          className="App-link"
//////          href="https://reactjs.org"
//////          target="_blank"
//////          rel="noopener noreferrer"
//////        >
//////          Learn React
//////        </a>
//////      </header>
//////    </div>
//////  );
//////}
//////
export default App;